import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { EssayHelper } from "../../helpers/essay.helper";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from '../common/app-top-bar';
import AppLoading from '../common/app-loading';
import '../../css/property-list.css';
import {
  Dialog, DialogTitle, FormLabel, FormControlLabel, Paper, Button, Divider,
  Radio, RadioGroup, Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid, TableHead, TableBody, TableCell, TableContainer, TableRow, Table
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useProperty } from '../../contexts/context-property';
import { AppHelper } from '../../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ToolbarPlugin from '../plugin/editor-toolbar';

export default function PopQuestion({
  onClose, open, onSubmit
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const oUser = AppHelper.userCacheGet();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal: 'center', msg: ''
  });
  const [questionData, setQuestionData] = useState({
    text: '',
    files: [],
    question: '',
  });

  const [questionList, setQuestionList] = useState([]);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [authOK, setAuthOK] = React.useState(false);
  const questionTypes = [
    { code: '-', name: '-- Select --' },
    { code: 'BUG', name: 'BUG REPORT' },
    { code: 'FEATURE', name: 'FEATURE REQUEST' },
    { code: 'GENERAL', name: 'GENERAL QUESTION' },
  ];
  const [question, setQuestion] = useState('');
  const [filterText, setFilterText] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const fetchFilteredQuestions = async (searchText) => {
    try {
      setFilterText(searchText);
      var filteredList = questionList.filter(
        (x) =>
          x.title.toLowerCase().includes(searchText.toLowerCase()) ||
          x.grade.toLowerCase().includes(searchText.toLowerCase()) ||
          (x.topic && x.topic.toLowerCase().includes(searchText.toLowerCase()))
      );
      setFilteredQuestions(filteredList);
    } catch (error) {
      console.error("Error fetching filtered questions:", error);
    }
  };


  const fetchQuestions = async () => {
    var _res = await AppHelper.apiPost('api/question/list/all', { token: oUser.token });
    if (!_res || !_res.status) {
      //alert("Fail to get question");
      console.log('ERROR', _res);
      return;
    }

    _res.data.forEach(x => {
      x.words = x.topic.split(' ');
      x.isLongText = x.words.length > 10;
      x.isExpanded = false;
    });
    console.log('list', _res.data);
    setQuestionList(_res.data);
    setFilteredQuestions(_res.data);
  };

  const questionSelect = async (arg) => {
    setQuestion(arg.target.value);
  };

  useEffect(() => {
    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);

    if (_user) {

      fetchQuestions();
    }
  }, []);

  const handleSubmit = async (argQuestionId) => {
    //await handleQuestionSubmit();
    var _q = questionList.find(r => r.questionId == argQuestionId);
    if (!_q) {
      _q = -1;
      // alert('Invalid Question');
      // return;
    }
    if (onSubmit) {
      onSubmit(_q);
      onClose(_q.questionId ? _q.questionId : -1);
    }
    else onClose(question == '' ? -1 : question);
  };

  const handleQuestionSubmit = async () => {
    // if (question == '') {
    //   alert("Select a question");
    //   return;
    // }
    var _q = questionList.find(r => r.questionId == question);
    if (!_q) {
      _q = -1;
      // alert('Invalid Question');
      // return;
    }
    if (onSubmit) {
      console.log('question', _q);
      onSubmit(_q);
    }
    else onClose(question == '' ? -1 : question);
  };

  const handleClose = () => { onClose() };

  //const editor = useLexicalEditor(editorConfig);
  const [textContent, setTextContent] = useState('');

  //const editorInstance = useLexicalEditor(editorConfig);
  // const updateTextContent = () => {
  //   // Get the editor state and extract the text content
  //   editorRef.current.getEditorState().read(() => {
  //       const root = editorRef.current.getEditorState().read();
  //       const text = root.getTextContent();
  //       setTextContent(text);
  //     });
  // };
  const toggleExpanded = (arg) => {
    var _list = [...filteredQuestions];
    var _rec = _list.find(x => x.questionId == arg.questionId);
    if (!_rec) return;
    _rec.isExpanded = !_rec.isExpanded;
    setFilteredQuestions(_list);
  }
  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth
        maxWidth={false}
        sx={{ '& .MuiDialog-paper': { width: '75%', maxWidth: 'none' } }}
      >
        <DialogTitle>{"SELECT QUESTION"}</DialogTitle>
        <Box pl={2} pr={2} pb={2} alignItems="flex-start">
          <Grid container>
            <Grid item xs={12}>
              <Box style={{ textAlign: 'center' }}>
                <LoadingButton
                  onClick={() => handleSubmit('')} // Pass questionId to handleSubmit
                  loading={isSubmitting}
                  variant="outlined"
                >
                  <span>Submit without question</span>
                </LoadingButton>
              </Box>
              <Box m={0.5}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Filter questions"
                  onChange={(e) => fetchFilteredQuestions(e.target.value)}
                  value={filterText}
                />
              </Box>
              <Box m={0.5}>
                <TableContainer style={{ maxHeight: 400, overflowY: 'auto' }}
                  className="scrollbar-001"
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Grade</TableCell>
                        <TableCell>Ownership</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Content</TableCell>
                        <TableCell></TableCell> {/* Added 'Select' column */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredQuestions && filteredQuestions.length > 0 &&
                        filteredQuestions.map((item) => (
                          <TableRow key={item.questionId}
                          onClick={() => handleSubmit(item.questionId)}
                          sx={{cursor:'pointer', 
                            '&:hover':{backgroundColor: 'rgba(0, 0, 0, 0.1)'}, 
                          }}>
                            <TableCell>{item.grade}</TableCell>
                            <TableCell align="left">{item.saQuestion == 1 ? "SYSTEM" : item.uploadType == 0 ? "SELF" : "TUITION CENTER " + item.tuitonName}</TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>
                              {item.topic !== "-" ? (
                                <Typography>
                                  {item.isLongText}
                                  {item.isExpanded || !item.isLongText ? item.topic : item.words.slice(0, 10).join(" ") + "..."}
                                  {item.isLongText && (
                                    <span onClick={() => toggleExpanded(item)} style={{ display: 'block', margin: 0 }} className="showMoreLess">
                                      {item.isExpanded ? " Show Less" : " Show More"}
                                    </span>
                                  )}
                                </Typography>
                              ) : (
                                <Box component="span" sx={{ cursor: 'pointer' }}>
                                  <img
                                    src={`data:image/png;base64,${item.firstImage}`}
                                    alt={item.title}
                                    style={{ width: '2rem' }}
                                    onClick={() => {
                                      const byteCharacters = atob(item.firstImage);
                                      const byteNumbers = new Array(byteCharacters.length);

                                      for (let i = 0; i < byteCharacters.length; i++) {
                                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                                      }

                                      const byteArray = new Uint8Array(byteNumbers);
                                      const blob = new Blob([byteArray], { type: 'image/png' });  // Create Blob from binary data
                                      const blobUrl = URL.createObjectURL(blob);

                                      window.open(blobUrl, '_blank');
                                    }}
                                  />
                                </Box>
                              )}
                            </TableCell>
                            {/* <TableCell> {/* Added 'Select' button for each row *\/}
                              <LoadingButton
                                onClick={() => handleSubmit(item.questionId)} // Pass questionId to handleSubmit
                                loading={isSubmitting}
                                variant="contained"
                              >
                                <span>Select</span>
                              </LoadingButton>
                            </TableCell> */}
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );

}
