import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import PopQuestion from './pop-question';
import '../../App.css';
import AppBar from '@mui/material/AppBar';
import { EssayHelper } from "../../helpers/essay.helper";
import ScanPopup from './scan-popup';
import ScanCam from './scan-cam';
import {
  Avatar, Button, Box, Card, CircularProgress, FormControl, Grid, IconButton, Input, InputLabel,
  Menu, MenuItem,
  Select, Stack, Switch, TextField, Typography, FormControlLabel, Checkbox,
  imageListClasses
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { auth } from '../../firebase';
import { useUser } from '../../UserContext';
import { AppHelper } from '../../AppHelper';
import './essay-form.css';
import PopScanAgain from "./pop-scan-again";
import EssayViewQuestion from './essay-view-question';
//import Typewriter from 'typewriter-effect/dist/core';
const configJson = require('../../config.json');
const dayjs = require('dayjs');

const useTypewriter = (text, speed = 70) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        setDisplayText(prevText => prevText.replace(/\|$/, '') + text.charAt(i) + '|');
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, speed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [text, speed]);

  return displayText;
};
function Typewriter(props) {
  const { text, speed } = props;
  const displayText = useTypewriter(text, speed);

  const handleClick = () => {
    if (props.onClick) props.onClick();
  };
  return <div onClick={handleClick}
    style={{
      color: '#999',
      fontSize: '1.3rem', fontFamily: "'Playpen Sans', cursive", lineHeight: '35px',
      background: 'linear-gradient(to bottom, white 34px, #00b0d7 1px)', backgroundSize: '100% 35px',
      minHeight: '25rem',
      direction: 'ltr',
      width: '100%', textAlign: 'left', padding: '0', border: 'none', outline: 'none', overflow: 'auto',
    }}
  >{displayText}</div>;
}
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function EssayFormV2(props) {
  const { drawerWidth, isClosing, viewMode, essayFormDone, profileId, essayId, onDeleteDraft } = props;
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupQuestionOpen, setPopupQuestionOpen] = useState(false);
  const [questionId, setQuestionId] = React.useState('');
  const [isScanCamOpen, setIsScanCamOpen] = useState(false);
  const navigate = useNavigate();
  const [authOK, setAuthOK] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [oUser, setOUser] = React.useState(null);
  const [isCropped, setIsCropped] = useState(false);
  const [msgText, setMsgText] = React.useState('');
  const [msgTextOld, setMsgTextOld] = React.useState('');
  const [msgText2, setMsgText2] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const txtName = useRef(null);
  const [txtNameOK, setTxtNameOK] = useState(true);
  const [essayTitle, setEssayTitle] = React.useState('');
  const [vMode, setVMode] = React.useState('NEW');
  const [charCount, setCharCount] = React.useState(0);
  // const [gradeOK, setGradeOK] = useState(true);
  const [grade, setGrade] = useState('P5');
  const [version, setVersion] = useState('4.0');
  const [versionCorr, setVersionCorr] = useState('2.0');
  const [gradeList, setGradeList] = useState([]);

  const [imgs, setImgs] = useState([]);

  const [doneEval, setDoneEval] = React.useState(false);
  const [doneCorrect, setDoneCorrect] = React.useState(false);
  const [evalStatus, setEvalStatus] = React.useState('Grammar');
  const [evalStatusP, setEvalStatusP] = React.useState(0);
  const [isSa, setIsSa] = React.useState(false);
  const [evalStatusSP, setEvalStatusSP] = React.useState({ done: false, v: 50 });
  const [evalStatusGM, setEvalStatusGM] = React.useState({ done: false, v: 50 });
  const [evalStatusVC, setEvalStatusVC] = React.useState({ done: false, v: 50 });
  const [evalStatusST, setEvalStatusST] = React.useState({ done: false, v: 50 });
  const [savingDraft, setSavingDraft] = React.useState(false);
  const [draftId, setDraftId] = React.useState('');
  const [newEssayId, setNewEssayId] = React.useState('');
  const [titlePlaceHolder, setTitlePlaceHolder] = React.useState('TITLE');
  const [bodyPlaceHolder, setBodyPlaceHolder] = React.useState('');
  //const [timerSaveDraft, setTimerSaveDraft] = useState(null);
  const [question, setQuestion] = React.useState({});
  const timerSaveDraft = useRef(null);
  const gradeList0 = [
    // { v: 'P1', n: 'PRIMARY 1'},
    // { v: 'P2', n: 'PRIMARY 2'},
    // { v: 'P3', n: 'PRIMARY 3'},
    { v: 'P4', n: 'PRIMARY 4', wMin: 120 },
    { v: 'P5', n: 'PRIMARY 5', wMin: 150 },
    { v: 'P6', n: 'PRIMARY 6', wMin: 150 }
  ];
  const verList = [
    { v: '1', n: 'Score 1.0' },
    { v: '2', n: 'Score 2.0' },
    { v: '2.1', n: 'Score 2.1' },
    { v: '2.2', n: 'Score 2.2' },
    { v: '3.0', n: 'Score 3.0' },
    { v: '3.1', n: 'Score 3.1' },
    { v: '4.0', n: 'Score 4.0' },
  ];
  const verListCorr = [
    { v: '1.0', n: 'Correction 1.0' },
    { v: '2.0', n: 'Correction 2.0' },
  ];

  const [formValid, setFormValid] = useState(true);
  const [formMsgError, setFormMsgError] = useState('');
  const [bodyRows, setBodyRows] = useState(10);
  const refPopScan = useRef(null);
  const [showTypewriter, setShowTypewriter] = useState(false);
  const [scanAgain, setScanAgain] = useState(false);
  const [isEnhancedTranscription, setIsEnhancedTranscription] = useState(false);
  const [scanAgainStart, setScanAgainStart] = useState(false);
  const [scanComplete, setScanComplete] = useState(false);
  const [useEnhanced, setUseEnhanced] = useState(false);

  const fetchGrades = async (oUser) => {
    var grade = await EssayHelper.essayGradeGet(oUser.token);
    if(grade.length<1){
      alert('Failed to load the grade list! ' + oUser.token);
      return;
    }
    setGradeList(grade);
  };

  var timerTypewriter = null;
  useEffect(() => {
    //console.log('title place holder changed', titlePlaceHolder);
    //console.log('title', txtName.current.value);
    if (txtName.current.value.length < 1) {
      if (titlePlaceHolder == 'TITLE') {
        //console.log('--- if');
        if (timerTypewriter) clearTimeout(timerTypewriter);
        timerTypewriter = setTimeout(() => {
          typewriterStart();
        }, 1000 * 3);
      }
      else {
        //console.log('--- else');
        if (timerTypewriter) clearTimeout(timerTypewriter);
        timerTypewriter = setTimeout(() => {
          typewriterStart();
        }, 1000 * 0.1);
      }
    }
    else {
      //console.log('txt name', txtName.current.value);
      setTitlePlaceHolder('TITLE');
      setBodyPlaceHolder('');
    }
  }, [titlePlaceHolder, bodyPlaceHolder, txtName]);

  useEffect(() => {
    if (isPopupOpen) {
      txtName.current.value = ""; // Reset the value of txtName input field
    }
  }, [isPopupOpen]);

  useEffect(() => {
    if (!essayId) {
      txtName.current.value = "";
      setMsgText("");
      setMsgText2("");
      return;
    }
    var _user = AppHelper.userCacheGet();
    AppHelper.apiPost('api/essay/detail', { token: _user.token, essayId: essayId }).then(_res => {
      if (_res && _res.status) {
        setDraftId(essayId);
        if (txtName && txtName.current) {
          txtName.current.value = _res.data.topic;

          setMsgText(_res.text.replace(/\n/g, "<br/>"));
          setMsgText2(_res.text.replace(/\n/g, "<br/>"));
        }
      }
    });
  }, [essayId]);

  useEffect(() => {
    var _user = AppHelper.userCacheGet();
    setAuthOK(_user != null);
    setOUser(_user);
    if (_user && _user.grade) {
      fetchGrades(_user);
      setGrade(_user.grade);
    }
  }, []);
  useEffect(() => {
    if (props.viewMode)
      setVMode(props.viewMode);
  }, [props.viewMode]);
  // useEffect(() => {
  //   console.log(props.profileId);
  // }, [props.profileId]);
  useEffect(() => {
    setIsSa(props.isSa);
    txtName.current.focus();
  }, [props.isSa]);

  const typewriterStart = () => {
    //console.log('typewriterStart', titlePlaceHolder);
    if (titlePlaceHolder == 'TITLE') {
      setTitlePlaceHolder('');
    }
    else {
      var _title = "Sammy's Quest for the Golden Acorn";
      if (titlePlaceHolder != _title) {
        var _titleNow = _title.substring(0, titlePlaceHolder.length + 1);
        //console.log('_titleNow',_titleNow);
        setTitlePlaceHolder(_titleNow);
      }
      else {
        var _body = "Once upon a time in a beautiful green park, there lived a small, friendly squirrel named Sammy. Sammy loved playing hide and seek with his friends, but he had a big dream. He wanted to find the Golden Acorn, a legendary nut said to be hidden somewhere in the park. Every day, Sammy would go on an adventure, looking under leaves and climbing tall trees, hoping to find the acorn. One sunny day, while searching near the old oak tree, Sammy met a wise old owl. The owl told Sammy that the Golden Acorn was not just a nut, but a symbol of bravery and determination. Encouraged by the owl's words, Sammy didn't give up. He continued his search with even more enthusiasm. Finally, after many days of looking, Sammy found the Golden Acorn. It was more beautiful than he had imagined. But the most important thing he found was not the acorn, but the courage and persistence he had within himself all along. Sammy learned that with determination and a positive attitude, you can achieve your dreams. And that was the greatest treasure of all.";
        if (bodyPlaceHolder.length < _body.length) {
          var _bodyNow = _body.substring(0, bodyPlaceHolder.length + 1);
          setBodyPlaceHolder(_bodyNow);
        }
      }
    }
    // setTimeout(() => {
    //   typewriterStart();
    // }, 1000*1);
  }
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCropChange = (event) => {
    setIsCropped(event.target.checked); // Update state when checkbox is clicked
  };

  const handleClose = () => { setAnchorEl(null) };
  const msgTextChange = (arg) => {
    //setMsgBox(arg.target.value);
    var _txt = arg.target.value;
    setMsgText(_txt);
    const words = _txt.trim().split(/\s+/);
    setCharCount(words.length);

    const textareaLineHeight = 35;
    const previousRows = arg.target.rows;
    const currentRows = Math.ceil(arg.target.scrollHeight / textareaLineHeight);
    arg.target.rows = currentRows;
    if (currentRows !== previousRows) {
      setBodyRows(currentRows);
    }
  };

  const divRef = useRef(null);

  const msgTextChangeV2 = () => {
    const _txt = divRef.current.innerText.trim();
    // setMsgText(_txt);
    setMsgText2(_txt);
    const words = _txt.trim().split(/\s+/);
    setCharCount(words.length);
    if (words.length > 0)
      saveDraft();
  };

  const msgTextPaste = (event) => {
    // const _txt = event.clipboardData.getData('text');
    // setMsgText(_txt);
    // const words = _txt.trim().split(/\s+/);
    // setCharCount(words.length);
  };

  const saveDraft = () => {
    if (timerSaveDraft) {
      //console.log('cleared : ', timerSaveDraft.current);
      clearTimeout(timerSaveDraft.current);
    }
    timerSaveDraft.current = setTimeout(() => {
      onSaveDraft();
    }, 1000 * 3);
    //console.log('v', timerSaveDraft.current);
  };
  // const saveDraft2 = ()=>{
  //   window.clearTimeout(timerSaveDraft);
  //   console.log('cleared : ', timerSaveDraft);
  //   timerSaveDraft = window.setTimeout(() => {
  //     onSaveDraft();
  //   }, 1000*3);
  //   console.log('timer : ' + timerSaveDraft);
  // };
  const onSaveDraft = async () => {
    //if(1==1) return;
    if (!txtName || !txtName.current) return;
    if (savingDraft) {
      saveDraft();
      return;
    }
    console.log('Saving Draft :: ', version, versionCorr, profileId);
    //if(1==1) return;
    var _topic = txtName.current.value;
    if (!_topic || _topic.length < 1) {
      _topic = '';
    }
    var _grade = gradeList.find(g => g.v == grade);
    if (!_grade) {
      setFormMsgError('Please select Grade.');
      setFormValid(false);
      return;
    }
	
    var _msgText = msgText2;
    console.log('_msgText', _msgText);
    _msgText = _msgText.replace(/<div style='color:white;background-color:blue;display:inline-block'>Page Break<\/div>/g, "");
    //_msgText = _msgText.replace(/<span style="color:#7486ec;background-color: yellow">(.*?)<\/span>/g, '$1');
    _msgText = _msgText.replace(/<[^>]*>/g, "");
    _msgText = _msgText.replace(/“/g, '"');
    _msgText = _msgText.replace(/”/g, '"');
	_msgText = _msgText.replace(/\bPage Break\b/g, '');
    _msgText = _msgText.replace(/‘/g, "'");
    _msgText = _msgText.replace(/’/g, "'");	
    _msgText = _msgText.replace(/\n\n\n/gi, "\n\n");
    _msgText = _msgText.replace(/,(?!\s)/g, ', '); //-- add space behind comma if there is not.
    _msgText = _msgText.replace(/\s{2,}/g, ' '); //-- remove space when multiple continuous spaces. (Unnecessary Space)
    _msgText = _msgText.replace(/\s*,/g, ','); //-- replace the space before comma if any. (Unnecessary Space)
    var _url = 'api/essay/create/draft';
    // if(version == '2') _url += '/v2';
    // else if(version == '2.1') _url += '/v2.1/';
    // else if(version == '2.2') _url += '/v2.2/';
    _url = 'api/essay/create/draft';
    var _payload = {
      token: oUser.token, imgs: imgs, topic: _topic, grade: grade, addScore: 0, text: _msgText, profileId: profileId,
      verScore: 'v' + version,
      verCorr: 'v' + versionCorr
    };
    if (draftId && draftId.length > 0)
      _payload.essayId = draftId;
    setSavingDraft(true);
    var _res = await AppHelper.apiPost(_url, _payload);
    setSavingDraft(false);
    if (!_res || !_res.status) {
      console.log('Failed to save the draft!');
      return;
    }
    setDraftId(_res.data.essayId);
    var _resEssay = _res.data;
  }
  const checkEssayStatus = async (argEssayId) => {
    var _res = await AppHelper.apiPost('api/essay/detail', { token: oUser.token, essayId: argEssayId });
    if (!_res || !_res.status) {
      alert('ERROR: Failed to load!');
      return;
    }
    //console.log('essay-detail', _res.data);
    checkEssayEvalStatus(_res.data.remark);
    if (_res.data.status == 8) {  //-- ERROR in EVAL or CORR
      console.log('error: ');
      alert('Submission failed. Please try again!');
      setVMode('NEW');
      // setTimeout(() => {
      //   essayFormDone({code:'ERROR', essayId: argEssayId});
      // }, 1.5*1000);
      return;
    }
    if (_res.data.status != 5) {
      if (_res.data.status == 3)
        setDoneEval(true);
      if (_res.data.status == 4)
        setDoneCorrect(true);

      setTimeout(() => {
        checkEssayStatus(argEssayId);
      }, 3 * 1000);
      return;
    }
    setTimeout(() => {
      essayFormDone({ code: 'NEW', essayId: argEssayId });
    }, 10 * 1000);
  }
  const checkEssayEvalStatus = (argStatus) => {
    console.log('essay status', argStatus);
    if (!argStatus || argStatus.length != 5) return;
    if (argStatus.charAt(4) == '1') setEvalStatusSP({ done: true, v: 100 });
    if (argStatus.charAt(3) == '1') setEvalStatusGM({ done: true, v: 100 });
    if (argStatus.charAt(2) == '1') setEvalStatusVC({ done: true, v: 100 });
    if (argStatus.charAt(1) == '1') setEvalStatusST({ done: true, v: 100 });
    if (argStatus.charAt(0) == '1') setDoneCorrect(true);
  }
  const checkEssayEvalStatusV01 = (argStatus) => {
    var _rtn = '';
    switch (argStatus) {
      case '1111':
        _rtn = 'Finishing';
        setEvalStatusP(100);
        break;
      case '111':
        _rtn = 'Flow & Structure';
        setEvalStatusP(75);
        break;
      case '11':
        _rtn = 'Vocabulary';
        setEvalStatusP(50);
        break;
      case '1':
        _rtn = 'Grammar';
        setEvalStatusP(25);
        break;
      default:
        _rtn = 'Spelling';
        setEvalStatusP(0);
        break;
    }
    setEvalStatus(_rtn);
    // var x = 1;
    // var y = 3;

    // // Convert x and y to binary strings and pad them with zeroes to ensure consistent length
    // var binaryX = x.toString(2).padStart(8, '0'); // Assuming 32-bit representation for simplicity
    // var binaryY = y.toString(2).padStart(8, '0');

    // // Check if the first bit of binaryX and binaryY are the same
    // var firstBitSame = (parseInt(binaryX[0]) & parseInt(binaryY[0])) > 0;

    // console.log(firstBitSame, binaryX, binaryY); // Output: true or false
  }
  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const onScan = async () => {
    setPopupOpen(true);
    setUseEnhanced(false);
    setMsgTextOld('');
  };
  const onScanAgain = async () => {
    //setPopupOpen(true);
    setScanAgain(true);
    setScanAgainStart(false);
  };
  const onScanAgainCancel = async () => {
    setScanAgain(false);
  };
  const onScanAgainStart = async () => {
    setMsgTextOld(msgText);
    setScanAgainStart(true);
    setUseEnhanced(true);
  };
  const onUseEnhancedChange = (event) => {
    var _useEnhanced = event.target.checked
    setUseEnhanced(_useEnhanced);
    var _msgText = msgText;
    var _msgTextOld = msgTextOld;
    setMsgText(_msgTextOld);
    setMsgTextOld(_msgText);
  };
  const onDiscard = async () => {
    if (!window.confirm('Are you sure you want to discard the draft essay?')) return;
    var _payload = { token: oUser.token, essayId: draftId };
    var _url = 'api/essay/remove';
    var _res = await AppHelper.apiPost(_url, _payload);
    if (!_res || !_res.status) {
      alert("Fail to discard draft");
      return;
    }
    txtName.current.value = "";
    setMsgText('');
    setMsgText2('');
    divRef.current.innerText = '';
    onDeleteDraft(draftId);
    setDraftId(null);
  };
  // const onSelectQuestion = async () => {
  //   var _payload = {question: questionId}
  //   if (version === "4.0") {
  //     _payload.question = question==-1?'':question; // Add questionId if version is 4.0
  //     console.log('4.0', _payload);
  //   }
  // }

  const onSubmit = async () => {
    //console.log(version, versionCorr, profileId);
    var _topic = txtName.current.value;
    if (!_topic || _topic.length < 1) {
      setFormMsgError(`Please enter the Essay Title.`);
      setFormValid(false);
      return;
    }
    var _grade = gradeList.find(g => g.v == grade);
    if (!_grade) {
      setFormMsgError('Please select Grade.');
      setFormValid(false);
      return;
    }
    //console.log(_grade.minW, charCount);
    if (charCount < _grade.minW) {
      setFormMsgError(`Please submit an essay with a minimum of ${_grade.minW} words to evaluate at a ${_grade.n} level.`);
      setFormValid(false);
      return;
    }
    if (charCount > 750) {
      setFormMsgError('Submission limited to 750 words. Please review and adjust as necessary.');
      return;
    }
    
    if (version == 4.0 && questionId == '') {
      setPopupQuestionOpen(true);
    // if(!questionId){
    //   setPopupQuestionOpen(true);
    //   return;
    // }

      return;
    }
    //if(charCount)
    //setVMode('PROCESSING');
    setDoneEval(false);
    setDoneCorrect(false);
    var _msgText = msgText2;
    //console.log('_msgText iswato', _msgText);
    //_msgText = _msgText.replace(/<span style="color:#7486ec;background-color: yellow">(.*?)<\/span>/g, '$1');
	
    _msgText = _msgText.replace(/<div style='color:white;background-color:blue;display:inline-block'>Page Break<\/div>/g, "");
    _msgText = _msgText.replace(/<br\/>/g, '<br/>\n');
    _msgText = _msgText.replace(/<[^>]*>/g, "");
    _msgText = _msgText.replace(/“/g, '"');
	_msgText = _msgText.replace(/\bPage Break\b/g, '');
    _msgText = _msgText.replace(/”/g, '"');
    _msgText = _msgText.replace(/‘/g, "'");
    _msgText = _msgText.replace(/’/g, "'");
    _msgText = _msgText.replace(/\n\n\n/gi, "\n\n");
    _msgText = _msgText.replace(/,(?!\s)/g, ', '); //-- add space behind comma if there is not.
    _msgText = _msgText.replace(/(\.\.|\.(?!\s))/g, (match) => match === '..' ? match : '. '); //-- add space behind . if there is not.
    //_msgText = _msgText.replace(/\s{2,}/g, ' '); //-- remove space when multiple continuous spaces. (Unnecessary Space)
    _msgText = _msgText.replace(/ {2,}/g, ' '); //-- remove space when multiple continuous spaces. (Unnecessary Space) - keep the new line char
    _msgText = _msgText.replace(/\s*,/g, ','); //-- replace the space before comma if any. (Unnecessary Space)
    //console.log('^^^^^^^^^^^^^^ msg text update');

    var _url = 'api/essay/create';
    if (version == '2') _url += '/v2';
    else if (version == '2.1') _url += '/v2.1/';
    else if (version == '2.2') _url += '/v2.2/';
    _url = 'api/essay/create/withVersion/async';

    var _payload = { token: oUser.token, questionId: questionId, imgs: imgs, topic: _topic, grade: grade, addScore: 0, text: _msgText, profileId: profileId };
    if (draftId) {
      _payload = { token: oUser.token, questionId: questionId, imgs: imgs, topic: _topic, grade: grade, addScore: 0, text: _msgText, profileId: profileId, essayId: draftId };
    }

    if (version === "4.0") {
      _payload.questionId = questionId==-1?'':questionId; // Add questionId if version is 4.0
      console.log('4.0', _payload);
    }

    _payload.verScore = 'v' + version;
    _payload.verCorr = 'v' + versionCorr;
    console.log('api call', _url, _payload);
    // if(1==1){
    //   setFormMsgError('TEST');
    //   return;
    // }
    setVMode('PROCESSING');
    checkEssayEvalStatus('');
    var _res = await AppHelper.apiPost(_url, _payload);
    var _resEssay = _res.data;
    if (!_res || !_res.status) {
      alert('ERROR: Failed to create!');
      setVMode('NEW');
      setEssayTitle(_topic);
      return;
    }
    else {
      //alert('Submitted!');
      if (essayFormDone) {
        setVMode('PROCESSING');
        // var _resAi = await AppHelper.apiAiPost('essay/process/eval', {essay: msgText});
        // var _resAiCorr = await AppHelper.apiAiPost('essay/process/correct', {essay: msgText});
        // setVMode('VIEW');
        // var _scoreList = [];
        // var _scoreRes = 'NA';
        // if(_resAi.data && _resAi.data.resEval){
        //   _scoreList = JSON.parse(_resAi.data.resEval);
        //   _scoreRes = _resAi.data.resEval;
        // }
        // var _corrRes = 'NA';
        // // if(_resAi.data && _resAi.data.resCorr){
        // //   _corrRes = _resAi.data.resCorr;
        // // }
        // if(_resAiCorr.data && _resAiCorr.data.resCorr){
        //   _corrRes = _resAiCorr.data.resCorr;
        // }
        // var _res3 = await AppHelper.apiPost('api/essay/process', 
        //   {
        //     token: oUser.token, essayId: _resEssay.essayId, status: 3,
        //     scoreList: _scoreList,
        //     scoreResult: _scoreRes,
        //     correctionResult: _corrRes
        //   });
        checkEssayStatus(_resEssay.essayId);
        setNewEssayId(_resEssay.essayId);
        //essayFormDone(_res);
      }
    }
  };

  const handleScanComplete = (result, imgs, argIsEnhancedTranscription) => {
    setIsEnhancedTranscription(argIsEnhancedTranscription);
    var fullBody = "";
    result.data.forEach(item => {
      const { title, body } = item;
      // remove html tag
      var formattedBody = body.replace(/<[^>]*>/g, '');
      console.log('Scan completed with title: ' + title + ', body: ' + body);

      // get title
      if (title != "" && title != "Untitled" && txtName.current.value == "") {
        txtName.current.value = title;
      }
      // replace []
      formattedBody = formattedBody.replace(/\[(.*?)\]/g, '<span style="color:#7486ec;background-color: yellow">$1</span>');
      // // replace \n with <br/>
      // formattedBody = formattedBody.replace(/\n/g, '<br/>');

      const lastChar = formattedBody.trim().slice(-1);
      const isFullStop = lastChar === ".";

      console.log("formatted body: ", formattedBody);
      console.log("Last Char: ", lastChar, "|is full stop: ", isFullStop);

      if (isFullStop) {
        // formattedBody = formattedBody.replace(/\n/g, '<br/>');
         fullBody += formattedBody;
        fullBody += "<div style='color:white;background-color:blue;display:inline-block'>Page Break</div>";
      } else {
        fullBody += formattedBody.trim(-1);
        fullBody += ' '; // To add in a space if the next page's content is continued
        fullBody += "<div style='color:white;background-color:blue;display:inline-block'>Page Break</div>";
      }
      // fullBody += "<div style='color:white;background-color:blue;display:inline-block'>Page Break</div>";
    });
    fullBody = fullBody.replace(/<br\/>/g, '<br/>\n');
    fullBody = fullBody.replace(/\n/g, '<br/>');
    fullBody = fullBody.replace(/^<br\/>/, '');
    fullBody = fullBody.replace(/<div style='color:white;background-color:blue;display:inline-block'>Page Break<\/div>$/, '');
    const words = fullBody.trim().split(/\s+/);
    setCharCount(words.length);
    setImgs(imgs);
    setMsgText2(fullBody);
    setMsgText(fullBody);
    console.log('fullBody', fullBody);
    setScanComplete(true);
    setScanAgain(false);
  };

  const gradeSelect = async (arg) => {
    setGrade(arg.target.value);
    //setGradeOK(bufferTime>0);
  };
  const versionSelect = async (arg) => {
    setVersion(arg.target.value);
    //setGradeOK(bufferTime>0);
  };
  const versionSelectCorr = async (arg) => {
    setVersionCorr(arg.target.value);
    //setGradeOK(bufferTime>0);
  };
  const calculateRows = (text) => {
    const lineCount = text.split('\n').length;
    return Math.max(lineCount, 13);
  };

  const onCamOpen = () => {
    console.log('onCamOpen');
    setIsScanCamOpen(true);
  }
  const onCamClose = () => {
    console.log('onCamClose');
    setIsScanCamOpen(false);
  }
  const onCamCapture = (arg) => {
    setIsScanCamOpen(false);
    refPopScan.current.handleCamCapture(arg);
  }
  const typewirterClick = () => {
    setShowTypewriter(false);
    console.log('typewirterClick');
  }
  const onNewEssay = () => {
    console.log('onNewEssay');
    window.location.reload();
  }
  const onRefresh = () => {
    essayFormDone({ code: 'NEW', essayId: newEssayId });
  }
  const handleOpenQuestionDialog = () => {
    setPopupQuestionOpen(true);
  }
  const popQuestionSubmit = (argQuestion)=>{
    console.log('Selected Question: ', argQuestion)
    const transformedQuestions = {
      ...argQuestion,
      images:argQuestion.firstImage, 
      imageCount: argQuestion.images
    }
    setQuestion(transformedQuestions);
    setQuestionId(transformedQuestions.questionId);
    setPopupQuestionOpen(false);
    // setTimeout(() => {
    //   onSubmit();
    // }, 1000*1);
  }
  const popQuestionClose = (questionId) => {
    setQuestionId((questionId || questionId==-1) || '');
    setPopupQuestionOpen(false);
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {vMode != 'PROCESSING' &&
          <>
            <Grid container mt={0}>
              <Grid item xs={12}>
                {/* <Box display="flex" justifyContent="center">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box textAlign="center">
                Box 1
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box textAlign={{ xs: 'left', sm: 'right' }}>
                Box 2
              </Box>
            </Grid>
          </Grid>
        </Box> */}
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box flex="1">
                    {/* { scanAgain && (
          <Box>
            Processing ..
          </Box>
          )} */}
                    {!isEnhancedTranscription && scanComplete && (msgTextOld == '') &&
                      <Button sx={{ textTransform: 'none', fontWeight: 'bold' }}
                        //sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} 
                        onClick={onScanAgain} color="info" variant="text">{'Try Enhanced Transcription?'}</Button>
                    }
                    {scanComplete && (msgTextOld != '') &&
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Original</Typography>
                        <AntSwitch checked={useEnhanced} onChange={onUseEnhancedChange} inputProps={{ 'aria-label': 'ant design' }} />
                        <Typography>Enhanced</Typography>
                      </Stack>
                    }
                    {savingDraft && (
                      <Box>
                        Saving ..
                      </Box>
                    )}
                    {isSa && (
                      <>
                        <Select labelId='lblVer'
                          value={version}
                          onChange={versionSelect}
                          inputProps={{ //disableUnderline: true
                          }}
                          sx={{ "& fieldset": { border: 'none' }, marginLeft:'-0.8rem' }}
                        >
                          {verList.map((item) => (
                            <MenuItem key={item.v} value={item.v}>
                              {item.n}
                            </MenuItem>
                          ))}
                        </Select>
                        <Select labelId='lblVerCorr'
                          value={versionCorr}
                          onChange={versionSelectCorr}
                          inputProps={{ //disableUnderline: true
                          }}
                          sx={{ "& fieldset": { border: 'none' } }}
                        >
                          {verListCorr.map((item) => (
                            <MenuItem key={item.v} value={item.v}>
                              {item.n}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </Box>
                  <Box>
                    {/* <Select labelId='lblProfile' 
              value={profileId}
              onChange={profileSelect}
              label=""
              sx={{ "& fieldset": { border: 'none' } }}
            >
              {profiles.map((item) => (
                <MenuItem key={item.profileId} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select> */}
                    {gradeList && gradeList.length > 0 &&
                      <Select labelId='lblGrade'
                        value={grade}
                        onChange={gradeSelect}
                        label=""
                        inputProps={{
                          //disableUnderline: true
                        }}
                        sx={{ "& fieldset": { border: 'none' } }}
                      >
                        {gradeList.map((item) => (
                          <MenuItem key={item.v} value={item.v}>
                            {item.n}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  </Box>
                </Box>
                <Box sx={{ mt: 0, mb: 2 }}>
                  <Grid item>
                    <>
                    <Button 
                    className="app-btn-bg"
                    variant="contained" 
                    onClick={handleOpenQuestionDialog}
                    >
                      Choose Question
                    </Button>
                      <PopQuestion 
                        open={isPopupQuestionOpen}
                        onClose={popQuestionClose}
                        onSubmit={popQuestionSubmit}
                      ></PopQuestion>
                    </>
                  </Grid>
                  {question.questionId != null &&
                  <Card variant="outlined" sx={{padding:1.5, marginRight:0, borderRadius:'20px', marginTop:'1rem', fontSize:'18px'}}>
                    <div style={{marginBottom:'8px'}}>Selected Question</div>
                    <div> { <EssayViewQuestion question={question} />} </div>
                  </Card>
                  } 
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box flex="1" textAlign="center" color={'red'} fontWeight={'bold'}>
                    {!formValid &&
                      <div>
                        {formMsgError}
                      </div>
                    }
                  </Box>
                </Box>
                <Box backgroundColor={'yellow'} display={'flex'} justifyContent={'right'} >
                  <Box pt={0} backgroundColor={'red'}>
                    {/* <FormControl variant="standard" error={!gradeOK} inputProps={{ disableUnderline: true }} sx={{ "& fieldset": { border: 'none' } }}> */}
                    {/* <InputLabel id='lblGrade'>GRADE</InputLabel> */}
                    {/* </FormControl> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}
                sx={{
                  backgroundColor: '#fff', borderRadius: '20px', border: '1px solid rgba(0, 0, 0, 0.12)',
                  //overflowY:'hidden', 
                  '&::-webkit-scrollbar': { width: '6px', },
                  '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
                  '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '4px', },
                  '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
                }}>
                <Card variant="outlined"
                  sx={{
                    padding: 1.5, marginRight: 0, border: 'none', overflowY: 'auto', borderRadius: '20px',
                    '&::-webkit-scrollbar': { width: '6px', },
                    '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
                    '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '4px', },
                    '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
                  }}>
                  <Grid item xs={12} mb={1} mt={1}>
                    <Box sx={{ fontWeight: 'bold', textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                      <TextField
                        inputRef={txtName}
                        //value={essayTitle}
                        error={!txtNameOK} label="" variant="standard" fullWidth
                        // fontFamily: "Playpen Sans", fontSize:'2rem', textAlign:'right', 
                        sx={{ "& fieldset": { border: 'none' } }}
                        inputProps={{
                          //disableUnderline:true, 
                          style: {
                            fontFamily: "Playpen Sans", fontSize: '2rem',
                            textAlign: 'center', //disableUnderline: true
                          }
                        }}
                        placeholder={titlePlaceHolder}
                        spellCheck="false" autoComplete="off" autoCorrect="off" autoCapitalize="off"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        fontSize: '1.3rem', lineHeight: '35px', fontFamily: "'Playpen Sans', cursive", height: '30rem',
                        border: '0 solid #efefef', borderRadius: '6px', padding: '5px', marginLeft: '5px',
                      }}>
                      {showTypewriter && (

                        <Typewriter onClick={typewirterClick}
                          text="  Once upon a time in a beautiful green park, there lived a small, friendly squirrel named Sammy. Sammy loved playing hide and seek with his friends, but he had a big dream. He wanted to find the Golden Acorn, a legendary nut said to be hidden somewhere in the park. Every day, Sammy would go on an adventure, looking under leaves and climbing tall trees, hoping to find the acorn. One sunny day, while searching near the old oak tree, Sammy met a wise old owl. The owl told Sammy that the Golden Acorn was not just a nut, but a symbol of bravery and determination. Encouraged by the owl's words, Sammy didn't give up. He continued his search with even more enthusiasm. Finally, after many days of looking, Sammy found the Golden Acorn. It was more beautiful than he had imagined. But the most important thing he found was not the acorn, but the courage and persistence he had within himself all along. Sammy learned that with determination and a positive attitude, you can achieve your dreams. And that was the greatest treasure of all." />
                      )}
                      {/* <div class="typewriter-box">
                  <div class="typewriter">
      This is a very long line of text that will be animated with the typewriter effect. It can contain as many characters as needed, and the animation will still work properly.
  </div>
                  </div> */}
                      {/* <div className="typed-out-box">
                    <div className="typed-out">
                      Essay Ace
                      Once upon a time in a beautiful green park, there lived a small, friendly squirrel named Sammy. 
                      
                    </div>
                  </div> */}
                      {!showTypewriter && (
                        <div className="divBody"
                          data-text={bodyPlaceHolder}
                          ref={divRef}
                          spellCheck="false"
                          onInput={msgTextChangeV2}
                          onPaste={msgTextPaste}
                          // onKeyDown={(event) => {
                          //   if (event.key === 'Enter') {
                          //     event.preventDefault(); 
                          //     const selection = window.getSelection();
                          //     const range = selection.getRangeAt(0);
                          //     const br = document.createElement('br');
                          //     range.deleteContents();
                          //     range.insertNode(br);
                          //     range.setStartAfter(br);
                          //     range.collapse(true);
                          //     selection.removeAllRanges();
                          //     selection.addRange(range);
                          //   }
                          // }}
                          contentEditable="true"
                          dangerouslySetInnerHTML={{ __html: msgText }}
                          style={{
                            fontSize: '1.3rem', fontFamily: "'Playpen Sans', cursive", lineHeight: '35px',
                            background: 'linear-gradient(to bottom, white 34px, #00b0d7 1px)', backgroundSize: '100% 35px',
                            minHeight: '25rem',
                            direction: 'ltr', // Ensure left-to-right text direction
                            width: '100%', textAlign: 'left', padding: '0', border: 'none', outline: 'none', overflow: 'auto',
                          }}
                        >
                        </div>
                      )}

                      <textarea
                        onChange={msgTextChange}
                        onPaste={msgTextPaste}
                        value={msgText}
                        //rows={15} 
                        //rows={calculateRows(msgText)}
                        rows={bodyRows}
                        style={{
                          display: 'none', // Hide the textarea
                          fontSize: '1.3rem', fontFamily: "'Playpen Sans', cursive", lineHeight: '35px',
                          background: 'linear-gradient(to bottom, white 34px, #00b0d7 1px)', backgroundSize: '100% 35px',
                          width: '100%', textAlign: 'left', padding: '0', border: 'none', outline: 'none', overflow: 'auto',
                          //background:'none'
                        }}
                        spellCheck="false" autoComplete="off" autoCorrect="off" autoCapitalize="off"
                      >
                      </textarea>
                    </Box>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ mb: 1 }}>
                  {charCount}/750 words
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="flex-end" alignItems='center' flexDirection='column'>
                  {/* {validPhone && <Fab onClick={onWhatsapp} sx={{width:'4rem', height:'4rem', marginTop:'3px'}} color="" aria-label="Login">
              <img src="/img/WhatsApp_icon.png.webp" alt="Send Whatsapp" style={{width:'75%'}} />
            </Fab>} */}
                </Box>
              </Grid>
            </Grid>
            <Grid item>
              {/* { isSa && (
        <>      
          <FormControlLabel
            control={
              <Checkbox color="primary" 
                checked={isCropped} 
                onChange={handleCropChange} 
              />}
            label="AUTO DETECT"
          />
        </>
        )} */}
            </Grid>
            <Grid container pt={0.5} justifyContent="space-between">
              <Grid item>
                {
                  <Button
                    className="app-btn-bg"
                    //sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} 
                    onClick={onScan} color="info" variant="contained">{'SCAN'}</Button>
                }
              </Grid>
              {draftId && (
                <Grid item>
                  <Button
                    className="app-btn-bg"
                    sx={{
                      //backgroundColor: 'rgb(99, 136, 137)',
                      //":hover": { backgroundColor: 'rgb(157, 188, 152)' }
                    }}
                    onClick={onDiscard}
                    color="info"
                    variant="contained"
                  >
                    {'DISCARD'}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  className="app-btn-bg"
                  //</Grid>sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} 
                  onClick={onSubmit} color="info" variant="contained">{isEdit ? 'UPDATE' : 'SUBMIT'}</Button>
              </Grid>
            </Grid>
            <ScanPopup isClear={false} isOpen={isPopupOpen} isCropped={isCropped} onClose={handleClosePopup}
              onScanComplete={handleScanComplete} onCamOpen={onCamOpen} ref={refPopScan} isSa={isSa}
              scanAgain={scanAgainStart}
            />
            <PopScanAgain
              open={scanAgain}
              onCancel={onScanAgainCancel}
              onStart={onScanAgainStart}
            />
            <ScanCam isClear={false} isOpen={isScanCamOpen} onClose={onCamClose} onScanComplete={handleScanComplete} onCamCapture={onCamCapture} />
          </>
        }
        {(vMode == 'PROCESSING' || 1 == 2) &&
          <Grid container mt={1}>
            <Grid item xs={12} mt={1}>
              <Box style={{ textAlign: 'center' }} mt={1}>
                {/* We are working on your submission. */}
                We have received your submission. It'll take around 2 minutes to process.
              </Box>
              <Box style={{ textAlign: 'center' }} mt={3}>
                <Button className="app-btn-bg" onClick={onNewEssay} color="info" variant="contained">Try a New Essay</Button>
              </Box>
            </Grid>
            <Grid item xs={12} mt={1}>
              Processing..
              <LinearProgress color="info" sx={{ marginTop: '1rem' }} />
            </Grid>
            {/* <Grid item xs={12} mt={5}>
            <Box display={'flex'} alignItems={'center'}>
            { !doneEval &&
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress />
                <Box
                  sx={{
                    top: 0, left: 0, bottom: 0, right: 0,
                    position: 'absolute',
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" component="div" color="text.info">
                    {`${evalStatusP}%`}
                  </Typography>
                </Box>
              </Box>
            }
              { doneEval &&
                (
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant="determinate" value={100} color="success" />
                    <Box
                      sx={{
                        top: 0, left: 0, bottom: 0, right: 0,
                        position: 'absolute',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                      }}
                    >
                      <Typography variant="caption" component="div" color="text.info">
                        {`100%`}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
              <Typography sx={{marginLeft:'10px'}}>Evaluation ({evalStatus})</Typography>
            </Box>
          </Grid> */}
            <Grid item xs={12} mt={2}>
              <Box display={'flex'} alignItems={'center'}>
                {!evalStatusGM.done &&
                  <CircularProgress />
                }
                {evalStatusGM.done &&
                  (
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress variant="determinate" value={100} color="success" />
                      <Box
                        sx={{
                          top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                          display: 'flex', alignItems: 'center', justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                      </Box>
                    </Box>
                  )
                }
                <Typography sx={{ marginLeft: '10px' }}>Grammar</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Box display={'flex'} alignItems={'center'}>
                {!evalStatusSP.done &&
                  <CircularProgress />
                }
                {evalStatusSP.done &&
                  (
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress variant="determinate" value={100} color="success" />
                      <Box
                        sx={{
                          top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                          display: 'flex', alignItems: 'center', justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                      </Box>
                    </Box>
                  )
                }
                <Typography sx={{ marginLeft: '10px' }}>Spelling</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Box display={'flex'} alignItems={'center'}>
                {!evalStatusVC.done &&
                  <CircularProgress />
                }
                {evalStatusVC.done &&
                  (
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress variant="determinate" value={100} color="success" />
                      <Box
                        sx={{
                          top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                          display: 'flex', alignItems: 'center', justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                      </Box>
                    </Box>
                  )
                }
                <Typography sx={{ marginLeft: '10px' }}>Vocabulary</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Box display={'flex'} alignItems={'center'}>
                {!evalStatusST.done &&
                  <CircularProgress />
                }
                {evalStatusST.done &&
                  (
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress variant="determinate" value={100} color="success" />
                      <Box
                        sx={{
                          top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                          display: 'flex', alignItems: 'center', justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                      </Box>
                    </Box>
                  )
                }
                <Typography sx={{ marginLeft: '10px' }}>Structure & Flow</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Box display={'flex'} alignItems={'center'}>
                {!doneCorrect &&
                  <CircularProgress />
                }
                {doneCorrect &&
                  (
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress variant="determinate" value={100} color="success" />
                      <Box
                        sx={{
                          top: 0, left: 0, bottom: 0, right: 0,
                          position: 'absolute',
                          display: 'flex', alignItems: 'center', justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div" color="text.info">
                          {`100%`}
                        </Typography>
                      </Box>
                    </Box>
                  )
                }
                <Typography sx={{ marginLeft: '10px' }}>Correction</Typography>
              </Box>
            </Grid>
            {newEssayId && newEssayId.length > 0 &&
              <>
                <Grid item xs={12} mt={3}>
                  Taking too long?
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Button className="app-btn-bg" onClick={onRefresh} color="info" variant="contained">Refresh</Button>
                </Grid>
              </>
            }
          </Grid>
        }
      </Box>
      <PopQuestion
        open={isPopupQuestionOpen}
        onClose={popQuestionClose}
        onSubmit={popQuestionSubmit}
      />
    </>
  );
}