import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SaveIcon from '@mui/icons-material/Save';
import { pink, green, blue } from '@mui/material/colors';
import {
  Button, Box, TextField, Chip, Dialog, DialogTitle, Grid, IconButton,
  Paper, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
const configJson = require('../../config.json?v=20230925');
dayjs.extend(relativeTime);

export default function EssayViewQuestion ({
  question
}){
  //const [question, setQuestion] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isLongText, setIsLongText] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [textShow, setTextShow] = useState('');
  
  useEffect(()=>{
    console.log('question',question);
    if(!question){
      setIsValid(false);
      return;
    }
    setIsValid(true);
    questionInfoBind();
  }, [question]);

  const maxWordCount = 20;
  const questionInfoBind = ()=>{
    setTitle(question.title);
    var _text = (question.transcribe && question.transcribe.length>1) ? '' : question.topic;
    setText(_text);
    var _words = _text.split(' ');
    if(_words.length>maxWordCount){
      setIsLongText(true);
      setTextShow(_words.slice(0, maxWordCount).join(" ") + "...");
    }
    else{
      setTextShow(_text);
      setIsLongText(false);
    }
  }
  const toggleShowMore = ()=>{
    var _showMore = !isShowMore;
    var _text = text;
    var _words = _text.split(' ');
    if(!_showMore && _words.length>maxWordCount){
      setTextShow(_words.slice(0, maxWordCount).join(" ") + "...");
    }
    else{
      setTextShow(_text);
    }
    setIsShowMore(_showMore);
  }
  return(
    <>
    { isValid &&
    <Box>
      <div style={{fontSize:'16px', marginBottom:'4px'}}>{title}</div>
      <div style={{fontSize:'14px', marginBottom:'4px'}}>{textShow}
        { isLongText &&
        <span onClick={()=>toggleShowMore()} style={{ color: "blue", cursor: "pointer" }}>
          {isShowMore ? " Show Less" : " Show More"}
        </span>
        }
      </div>
      <div> {question.images != "" && question.images != null && 
        <img
        src={`data:image/png;base64,${question.images}`}
        alt={title}
        sx={{marginTop:'0.25rem'}}
        style={{ width: '2rem' }}
        onClick={() => {
          const byteCharacters = atob(question.images);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'image/png' });  // Create Blob from binary data
          const blobUrl = URL.createObjectURL(blob);

          window.open(blobUrl, '_blank');
        }}
      />
        } </div>
    </Box>
    }
    </>
  );
}