import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { pink, green, blue } from '@mui/material/colors';
import { diffWordsWithSpace } from 'diff';
import {
  Alert, Button, Box, Chip, Dialog, DialogTitle, Grid, IconButton,
  Paper, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default function PopScoreRule1_10 ({
  onClose, open, scoreInfo, essay, allowMark, onMarkDone
}){
  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
    if(!open || !essay) return;
    //fetchShareList();
  }, [open]);
  useEffect(()=>{
    if(!scoreInfo) return;
    console.log('scoreInfo',scoreInfo);
  }, [scoreInfo]);
  
  const handleClose = () =>{ onClose() };
  return(
    <Dialog onClose={handleClose} open={open} sx={{minWidth:'400px'}}>
      <DialogTitle>Score Rule</DialogTitle>
      { scoreInfo &&
      <>
      <Box padding={'0.5rem'} textAlign={'center'} fontWeight={'bold'}>
        {/* <Alert variant="filled" severity="info">
          {scoreInfo.data[scoreInfo.i].title}
        </Alert> */}
        <Chip color="primary" label={scoreInfo.data[scoreInfo.i].title} />
      </Box>
      <Box textAlign={'center'} padding={'0 2rem 1rem 2rem'}>
        { /^R/.test( scoreInfo.data[scoreInfo.i].code) &&
        <>
        {scoreInfo.data[scoreInfo.i].code.includes('6') &&
        "Entirely relevant with well-structured ideas; all points align closely with the topic."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('5') &&
        "Strongly relevant, with minor unrelated points; the overall topic focus is maintained."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('4') &&
        "Generally relevant; some sections are less clear or detailed, but the main ideas relate to the topic."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('3') &&
        "Includes relevant ideas but also some off-topic content; overall focus is inconsistent."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('2') &&
        "Significant unrelated content; weak focus on the main topic with numerous irrelevant ideas."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('1') &&
        "Mostly off-topic with little meaningful connection to the main theme."
        }
        </>
        }
        { /^L/.test( scoreInfo.data[scoreInfo.i].code) &&
        <>
        {scoreInfo.data[scoreInfo.i].code.includes('6') &&
        "Ideas flow seamlessly and logically, with clear descriptions."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('5') &&
        "Mostly logical and cohesive, with detailed explanations."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('4') &&
        "Largely logical, though some parts lack description."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('3') &&
        "Some inconsistencies or confusing points."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('2') &&
        "Multiple gaps in logic; descriptions are weak."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('1') &&
        "Disorganized and illogical flow throughout; descriptions are insufficient."
        }
        </>
        }
        { /^I/.test( scoreInfo.data[scoreInfo.i].code) &&
        <>
        {scoreInfo.data[scoreInfo.i].code.includes('6') &&
        "Unique and engaging opening; fully sets the scene (Who, What, When, Where)."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('5') &&
        "Engaging but familiar opening; adequately sets the scene (Who, What, When, Where)."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('4') &&
        "Opening present, covers the basics (Who, What, When, Where) but lacks depth."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('3') &&
        "No clear opening; addresses only three of the 4Ws (Who, What, When, Where)."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('2') &&
        "Lacks a clear start; addresses two of the 4Ws (Who, What, When, Where)."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('1') &&
        "No opening; covers only one of the 4Ws (Who, What, When, Where)."
        }
        </>
        }
        { /^B/.test( scoreInfo.data[scoreInfo.i].code) &&
        <>
        {scoreInfo.data[scoreInfo.i].code.includes('6') &&
        "Plot is thoroughly developed with clear progression."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('5') &&
        "Plot is adequately developed and structured."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('4') &&
        "Plot development is minimal but present."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('3') &&
        "Plot is underdeveloped, with noticeable gaps."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('2') &&
        "Plot is vague and confusing, with multiple missing parts."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('1') &&
        "Plot is disjointed and confusing, lacking coherence and direction."
        }
        </>
        }
        { /^C/.test( scoreInfo.data[scoreInfo.i].code) &&
        <>
        {scoreInfo.data[scoreInfo.i].code.includes('6') &&
        "Concludes meaningfully with reflections or future insights."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('5') &&
        "Concludes well with either reflections or future insights."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('4') &&
        "Ending is general and lacks specificity to the topic."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('3') &&
        "Abrupt ending with limited closure."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('2') &&
        "Abrupt and rushed, offering little closure."
        }
        {scoreInfo.data[scoreInfo.i].code.includes('1') &&
        "Unrelated and confusing ending, without a clear conclusion."
        }
        </>
        }
        { scoreInfo.data[scoreInfo.i].code.includes('GM-') &&
        <>
        Correct Word Percent (# Correct Words / # Total Words) <br/>must not be lower than {scoreInfo.data[scoreInfo.i].v}%
        </>
        }
        { scoreInfo.data[scoreInfo.i].code.includes('SP-') &&
        <>
        Correct Word Percent (# Correct Words / # Total Words) <br/>must not be lower than {scoreInfo.data[scoreInfo.i].v}%
        </>
        }
        { scoreInfo.data[scoreInfo.i].code.includes('VCA-') &&
        <>
        Correct Word Percent (# Correct Words / # Total Words) <br/>must not be lower than {scoreInfo.data[scoreInfo.i].v}%
        </>
        }
        { scoreInfo.data[scoreInfo.i].code.includes('VCX-') &&
        <>
        <div>
          1 Rare Word = 1 Complexity Point
        </div>
        <div style={{marginBottom:'0.5rem'}}>
          1 Uncommon Word = 0.5 Complexity Point
        </div>
        Complexity Percent (# Complexity Points / # Total Words) <br/>must not be lower than {scoreInfo.data[scoreInfo.i].v}%
        </>
        }
      </Box>
      <Box textAlign={'center'} paddingBottom={'2rem'}>
        <div style={{marginBottom:'0.5rem'}}>
          {/* <Chip label='Example' /> */}
        </div>
        { scoreInfo.data[scoreInfo.i].code.includes('GM-') &&
        <>
        <Chip label='Example' />
        <div>Total number of words: 200</div>
        <div>Minimum correct words: {200*(scoreInfo.data[scoreInfo.i].v/100)} ({scoreInfo.data[scoreInfo.i].v}%)</div>
        </>
        }
        { scoreInfo.data[scoreInfo.i].code.includes('SP-') &&
        <>
        <Chip label='Example' />
        <div>Total number of words: 200</div>
        <div>Minimum correct words: {200*(scoreInfo.data[scoreInfo.i].v/100)} ({scoreInfo.data[scoreInfo.i].v}%)</div>
        </>
        }
        { scoreInfo.data[scoreInfo.i].code.includes('VCA-') &&
        <>
        <Chip label='Example' />
        <div>Total number of words: 200</div>
        <div>Minimum correct words: {200*(scoreInfo.data[scoreInfo.i].v/100)} ({scoreInfo.data[scoreInfo.i].v}%)</div>
        </>
        }
        { scoreInfo.data[scoreInfo.i].code.includes('VCX-') &&
        <>
        <Chip label='Example' />
        <div>Total number of words: 200</div>
        <div>Minimum complexity points: {200*(scoreInfo.data[scoreInfo.i].v/100)} ({scoreInfo.data[scoreInfo.i].v}%)</div>
        </>
        }
      </Box>
      </>
      }
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
    </Dialog>
  );
}