const configJson = require('./config.json?v=20230925');

export const AppHelper = {
  async api(endpoint, method = 'POST', data = null, fetchSignal = null) {
    // var _proxyData = {
    //   url: configJson.apiUrl + '/' + endpoint,
    //   data: data
    // }
    var _proxyData = data;
    try {
      const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(_proxyData),// data ? JSON.stringify(data) : null,
        signal: fetchSignal
      };
      const res = await fetch(configJson.apiUrl + '/' + endpoint, options);
      //const res = await fetch(configJson.apiProxyUrl, options);
      const resData = await res.json();

      if (!res.ok) {
        throw new Error(resData.message || 'Request failed');
      }
      return resData;
    } catch (error) {
      console.log('API ERROR', error);
    }
  },
  apiUrlGet(endpoint){
    return configJson.apiUrl + '/' + endpoint;
  },
  async apiPost(endpoint, data = null, fetchSignal = null){
    return await this.api(endpoint, 'POST', data, fetchSignal);
  },
  async apiAuditPost(code, remark){
	  const oUser = AppHelper.userCacheGet();
    return await this.api("api/audit/create", 'POST', {token: oUser?oUser.token:'NA', code:code, remark:remark?remark:''});
  },
  async apiAi(endpoint, method = 'POST', data = null) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 460000);
    var _proxyData = {
      url: configJson.apiAiUrl + '/' + endpoint,
      data: data
    }
    try {
      const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(_proxyData),// data ? JSON.stringify(data) : null,
        signal: controller.signal
      };
      //const res = await fetch(configJson.apiAiUrl + '/' + endpoint, options);
      const res = await fetch(configJson.apiProxyUrl, options);
      clearTimeout(timeoutId);
      const resData = await res.json();

      if (!res.ok) {
        throw new Error(resData.message || 'Request failed');
      }
      return resData;
    } catch (error) {
      console.log('API ERROR', error);
    }
  },
  async apiAiPost(endpoint, data = null){
    return await this.apiAi(endpoint, 'POST', data);
  },
  async apiFunction(endpoint, data = null) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 460000);
    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data ? JSON.stringify(data) : null,
        signal: controller.signal
      };
      console.log('url', configJson.apiFunctionUrl + '/' + endpoint);
      const res = await fetch(configJson.apiFunctionUrl + '/' + endpoint, options);
      clearTimeout(timeoutId);
      const resData = await res.json();

      if (!res.ok) {
        throw new Error(resData.message || 'Request failed');
      }
      return resData;
    } catch (error) {
      console.log('API ERROR', error);
    }
  },
  async apiDirect(argUrl, method='POST', data=null){
    try{
      const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: data ? JSON.stringify(data) : null,
      };
      const res = await fetch(argUrl, options);
      const resData = await res.json();

      if (!res.ok) {
        throw new Error(resData.message || 'Request failed');
      }
      return resData;
    }
    catch(err){
      console.log('API ERROR', err);
    }
  },
  sortList(argList, argField, argOrder=1){
    if(argOrder===1)
      return [...argList].sort((a, b) => a[argField] - b[argField]);
    else
      return [...argList].sort((a, b) => b[argField] - a[argField]);
  },
  userCacheSet(argUser=null){
    if(!argUser)
      localStorage.removeItem('loginUser');
    else localStorage.setItem('loginUser', JSON.stringify(argUser));
  },
  userCacheUpdate(argUser){
    localStorage.setItem('loginUser', JSON.stringify(argUser));
  },
  userCacheGetString(){
    return localStorage.getItem('loginUser');
  },
  userCacheGet(){
    var _json = this.userCacheGetString();
    if(!_json || _json.length<1) return null;
    return JSON.parse(localStorage.getItem('loginUser'));
  },
  userCodeCacheSet(argCode=null){
    if(!argCode)
      localStorage.removeItem('authCode');
    else localStorage.setItem('authCode', argCode);
  },
  userCodeCacheGet(){
    return localStorage.getItem('authCode');
  },
  cacheInviteIdGet(){
    return localStorage.getItem('inviteId');
  },
  cacheInviteIdSet(inviteId=null){
    if(!inviteId)
      localStorage.removeItem('inviteId');
    else localStorage.setItem('inviteId', inviteId);
  },
  itemSStatus(isReady){
    return isReady ? 'Seller schedule is confirmed' : 'Pending Seller schedule';
  },
  profileDefaultCacheSet(argId=null){
    if(!argId)
      localStorage.removeItem('profileDefault');
    else localStorage.setItem('profileDefault', argId);
  },
  profileDefaultCacheGet(){
    return localStorage.getItem('profileDefault');
  },
  sampleEssay(){
    return configJson.sampleEssay;
  },
  shareUrl(shareId){
    return configJson.siteUrl + 'essay/share/' + shareId;
  },
  isEmptyString(arg){
    return !arg || !arg.length>0;
  },
  imgB64ToFile(base64String, filename = 'image.jpg'){
    var _arr = base64String.split(',');
    let mime = 'image/jpeg';
    let binaryString = '';
    if(_arr.length>1){
      const [header, data] = _arr;
      mime = header.match(/:(.*?);/)[1];
      binaryString = atob(data);
    }
    else{
      binaryString = atob(base64String);
    }
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uintArray = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < binaryString.length; i++) {
      uintArray[i] = binaryString.charCodeAt(i);
    }
  
    return new File([arrayBuffer], filename, { type: mime });
  },
  imgFileToB64(file){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Remove the data URL prefix
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  },
  decimal2(arg){
    
  },
  // sellerCacheGet(){
  //   var _json = this.userCacheGetString();
  //   if(!_json || _json.length<1) return null;
  //   return JSON.parse(localStorage.getItem('loginUser'));
  // },
  // sellerCacheSet(arg=null){
  //   if(!argCode)
  //     localStorage.removeItem('authCode')
  //   else localStorage.setItem('authCode', argCode);
  // }
};