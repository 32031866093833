import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../AppHelper';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SaveIcon from '@mui/icons-material/Save';
import { pink, green, blue } from '@mui/material/colors';
import {
  Button, Box, ButtonGroup, TextField, Chip, Dialog, DialogTitle, Grid,
  Paper, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
const configJson = require('../config.json?v=20230925');
dayjs.extend(relativeTime);

export default function PopInviteEmail ({
  onClose, open, tutList, tuiton
}){
  const [isLoading, setIsLoading] = useState(false);
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  const [email1, setEmail1] = useState(oUser? (oUser.email1 || ''):'');
  const [email1Sent, setEmail1Sent] = useState(false);
  const [name, setName] = useState('');
  const [accessList, setAccessList] = useState('');
  
  useEffect(()=>{
    if(!open) return;
    setEmail1Sent(false);
    setEmail1("");
    setAccessList([
      {v:'P1', a:0}, {v:'P2', a:0}, {v:'P3', a:0},
      {v:'P4', a:0}, {v:'P5', a:0}, {v:'P6', a:0},
    ]);
  }, [open]);
  const handleClose = () =>{ onClose() };
 
  const sendEmail = async()=>{
    if(AppHelper.isEmptyString(name)){
      alert('Name is reuried!');
      return;
    }
    if(AppHelper.isEmptyString(email1)){
      alert('Email is reuried!');
      return;
    }
    if(tutList && tutList.length>0){
      const _arrExist = tutList.filter(r=>r.email && r.email.toLowerCase() == email1.toLowerCase());
      if(_arrExist.length>0){
        alert('Invite or Tutor already exsists!');
        return;
      }
    }
    var _emailCode = 'INVITE';
    var _user = AppHelper.userCacheGet();
    var _access = '';
    accessList.forEach(r => {
      _access += `${r.v}:${r.a};`;
    });
    var _payload = {
      token: _user.token,
      name: name,
      email:email1, 
	    ccEmail:[], 
      code:_emailCode,
      accessRight: _access,
      param:{
		  "tuiton":tuiton,
      "link":'https://essayace.ai',
		  "acceptLink":configJson.siteUrl,
      "siteUrl":'https://essayace.ai'//configJson.siteUrl
      }
    };
    console.log(_payload);
    setIsLoading(true);
    var _res = await AppHelper.apiPost('api/tuiton/invite', _payload);
    setIsLoading(false);
    if (!_res || !_res.status) {
      alert('ERROR: Failed to send email!');
      return;
    }
    //alert('Email sent successfully!');
    setEmail1Sent(true);
  }
  const clickAccess = async(argGrade, argValue)=>{
    //console.log(profile, argGrade, argValue);
    var _grades = [...accessList];
    var _grade = _grades.find(r=>r.v==argGrade.v);
    _grade.a = argValue;
    setAccessList(_grades);
  };
  return(
    <>
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>INVITE (Email)</DialogTitle>
      <Box m={1}>
        <Grid container m={1}>
          <Grid item xs={12} pr={0.5}>
            <TextField
              margin="dense"
              label="Name"
              value={name}
              type="text"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} pr={0.5}>
            <TextField
              margin="dense"
              label="Email"
              value={email1}
              type="email"
              fullWidth
              onChange={(e) => setEmail1(e.target.value)}
            />
          </Grid>
          {accessList && accessList.map((gg, iGG) => (
            <React.Fragment key={'popAccessGrade_'+iGG}>
            <Grid item xs={4} style={{textAlign:'center'}}>
              {gg.v}
            </Grid>
            <Grid item xs={8}>
              <ButtonGroup aria-label="Basic button group" style={{marginTop:'2px'}}>
                 <Button
				  variant={gg.a === 0 ? 'contained' : 'outlined'}
				  sx={{
					backgroundColor: gg.a === 0 ? '#808080' : 'transparent', // Gray for contained
					color: gg.a === 0 ? '#fff' : 'inherit',                 // White text for gray
					borderColor: gg.a === 0 ? 'transparent' : 'primary.main', // Border for outlined
					'&:hover': {
					  backgroundColor: gg.a === 0 ? '#696969' : 'transparent', // Darker gray on hover
					},
				  }}
				  onClick={() => clickAccess(gg, 0)}
				>
				  NA
				</Button>
                <Button variant={gg.a==1?'contained':'outlined'} onClick={()=>clickAccess(gg, 1)}>VIEW</Button>
                <Button
				  variant={gg.a === 2 ? 'contained' : 'outlined'}
				  sx={{
					backgroundColor: gg.a === 2 ? 'rgba(19, 130, 117, 1)' : 'transparent', // Teal for contained
					color: gg.a === 2 ? '#fff' : 'inherit',                // White text for teal
					borderColor: gg.a === 2 ? 'transparent' : 'primary.main', // Border for outlined
					'&:hover': {
					  backgroundColor: gg.a === 2 ? '#138275' : 'transparent', // Darker teal on hover
					},
				  }}
				  onClick={() => clickAccess(gg, 2)}
				>
				  MANAGE
				</Button>
              </ButtonGroup>
            </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} p={0.5} mt={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {/* <Tooltip title="share via Email">
              <IconButton onClick={() => sendEmail(1)} area-label="share via Email">
                <EmailIcon style={{color:pink[500]}} />
              </IconButton>
            </Tooltip> */}
            {!isLoading && !email1Sent &&
              <Button onClick={()=>sendEmail()} className="app-btn-bg" color="info" variant="contained" size="large" style={{marginRight:'2px'}}
                endIcon={<ForwardToInboxIcon />}
              >
                SEND
              </Button>
            }
            {email1Sent &&
              <MarkEmailReadIcon sx={{color:'rgba(19,130,117,1)'}}/>
            }
          </Grid>
        </Grid>
      </Box>
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
    </Dialog>
    </>
  );
}